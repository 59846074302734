import { defaultConfig } from "config";
import { CustomerInteraction, Lead } from "interfaces";
import { async } from "q";
import Axios from "services/axios";
import { replaceParam } from "utils/common";

async function getLeads(
  leadStatus: string | null,
  callStatus: string | null,
  fromDate: number | null,
  toDate: number | null,
  phoneNumber: string | null,
  page: number,
  pageSize: number,
  dealerId: string | null,
  userId: string | null,
  received: boolean|null
): Promise<any[]> {
  const authHeaderStr = "Bearer " + localStorage.getItem("token");
  Axios.axiosInstance.defaults.headers.common["Authorization"] = authHeaderStr;
  var url = defaultConfig.urls.allusedCarsBaseUrl + defaultConfig.urls.getLeads;

  url = url + "?page=" + page + "&pageSize=" + pageSize;
  if (leadStatus && leadStatus != "") {
    url = url + "&leadStatus=" + leadStatus;
  }
  if (callStatus) {
    url = url + "&leadCallStatus=" + callStatus;
  }
  if (toDate) {
    url = url + "&toDate=" + toDate;
  }
  if (fromDate) {
    url = url + "&fromDate=" + fromDate;
  }
  if (phoneNumber) {
    url = url + "&phoneNumber=" + phoneNumber;
  }
  if (dealerId) {
    url = url + "&dealerId=" + dealerId;
  }
  if (userId) {
    url = url + "&assignToUser=" + userId;
  }

  if(received)
  {
    url = url + "&received=" + received;
  }

  const response = await Axios.axiosInstance.get(url);

  return [response.data.leads, response.data.totalCount, response.status];
}

async function saveLead(lead: Lead): Promise<any[]> {
  const url =
    defaultConfig.urls.allusedCarsBaseUrl + defaultConfig.urls.saveLead;

  const response = await Axios.axiosInstance.post(url, lead);

  return [response.data, response.status];
}

async function changeLeadStatus(
  leadId: string,
  comments: string | undefined,
  leadStatus: string | undefined
): Promise<any[]> {
  const authHeaderStr = "Bearer " + localStorage.getItem("token");
  Axios.axiosInstance.defaults.headers.common["Authorization"] = authHeaderStr;
  const url =
    defaultConfig.urls.allusedCarsBaseUrl +
    defaultConfig.urls.updateLead +
    leadId;

  var data = {
    leadStatus: leadStatus,
    comments: comments,
  };
  const response = await Axios.axiosInstance.put(url, data);

  return [response.data, response.status];
}

async function changeLeadStatusByLead(lead: Lead): Promise<any[]> {
  if (lead.leadStatus == "ALL") {
    lead.leadStatus = undefined;
  }
  const authHeaderStr = "Bearer " + localStorage.getItem("token");
  Axios.axiosInstance.defaults.headers.common["Authorization"] = authHeaderStr;
  const url =
    defaultConfig.urls.allusedCarsBaseUrl +
    defaultConfig.urls.updateLead +
    lead.leadId;

  const response = await Axios.axiosInstance.put(url, lead);

  return [response.data, response.status];
}

async function sendCustomerMsg(leadId: string, msg:CustomerInteraction): Promise<any[]> {
 
  const authHeaderStr = "Bearer " + localStorage.getItem("token");
  Axios.axiosInstance.defaults.headers.common["Authorization"] = authHeaderStr;
  const url =
    defaultConfig.urls.allusedCarsBaseUrl +
    defaultConfig.urls.sendCustomerMsg ;
 let c = replaceParam(url, [ leadId]);
  const response = await Axios.axiosInstance.post(c, msg);

  return [response.data, response.status];
}
const assignUserToLead = async (
  leadId: string,
  userId: string,
  name: string
): Promise<any> => {
  const url =
    defaultConfig.urls.allusedCarsBaseUrl +
    `api/internal/leads/${leadId}/assignTo?userId=${userId}&name=${name}`;
  const authHeaderStr = "Bearer " + localStorage.getItem("token");
  Axios.axiosInstance.defaults.headers.common["Authorization"] = authHeaderStr;
  const response = await Axios.axiosInstance.put(url);
  if (response.status == 200) {
    alert("User assigned successfully!");
  }
  return [response.data, response.status];
};

const sendToBigDealer= async (
  leadId: string
 
): Promise<any> => {
  const url =
    defaultConfig.urls.allusedCarsBaseUrl +
    `api/internal/leads/${leadId}/sendToBigDealer`;
  const authHeaderStr = "Bearer " + localStorage.getItem("token");
  Axios.axiosInstance.defaults.headers.common["Authorization"] = authHeaderStr;
  const response = await Axios.axiosInstance.put(url);
  if (response.status == 200) {
    alert("User assigned successfully!");
  }
  return [response.data, response.status];
};

export default {
  getLeads,
  changeLeadStatus,
  saveLead,
  changeLeadStatusByLead,
  assignUserToLead,
  sendToBigDealer,
  sendCustomerMsg
};
